import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import { AppLayout } from 'svs-utils/react';

import { Home } from './components/index.js';

function AppRouter(props) {
    return (
        <Routes>
            <Route path='/' element={<AppLayout />}>
                <Route path='*' element={<div>404</div>} />
                <Route path='404' element={<div>404</div>} />
                <Route path=':homePath' element={<Home />} />
                <Route index element={<Navigate to='/jsonviewer' />} />
            </Route>
        </Routes>
    );
}

export default AppRouter;
