import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
// import dateFormat from 'dateformat';

import { IronCondorCalculator, GridCalculator, JsonViewTool } from './tools/index.js';
import './home.scss';

var menuItems = {
    jsonviewer: { title: 'JSON Viewer Tool', content: () => <JsonViewTool /> },
    ironcondorcalc: { title: 'Iron Condor Calculator', content: () => <IronCondorCalculator /> },
    gridcalc: { title: 'Video Grid Calculator', content: () => <GridCalculator /> },
};

function Home(props) {
    var [menuOpen, setMenuOpen] = useState(true);

    var navigate = useNavigate();
    var urlParams = useParams();

    useEffect(() => {
        if (!menuItems[urlParams.homePath?.toLowerCase()]) {
            navigate('/404');
            return;
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    var homePath = urlParams.homePath?.toLowerCase();

    return (
        <div className='home'>
            <div className='mainToolBar homeBoxShadow noSelect'>
                <div className='mainMenuButton' onClick={() => setMenuOpen(!menuOpen)}>Menu</div>
                <div className='titleBar'>Dashboard - {menuItems[homePath]?.title}</div>
            </div>
            {menuOpen && (
                <div className='mainMenu homeBoxShadow'>
                    {Object.keys(menuItems).map((itemKey) => (
                        <Link
                            className={classNames('menuItem', { selected: homePath === itemKey })}
                            key={itemKey}
                            to={`/${itemKey}`}
                        >
                            {menuItems[itemKey].title}
                        </Link>
                    ))}
                </div>
            )}
            <div
                className={classNames('homeContentContainer', homePath)}
                style={{ gridColumn: `span ${menuOpen ? 1 : 2}` }}
            >
                <div className='homeContent'>
                    {menuItems[homePath]?.content()}
                </div>
            </div>
        </div>
    );
}

export default Home;
