import React from 'react';
import classNames from 'classnames';

import { JsonViewer, Input } from 'svs-utils/react';

import { useStateSlice } from '../../../utils/reactUtils.js';

import './jsonViewTool.scss';

function JsonViewTool(props) {
    var [jsonSlice, setJsonSlice] = useStateSlice('jsonViewTool');

    var setTabState = (index, key, value) => {
        var newTabs = [...jsonSlice.tabs];
        newTabs[index] = { ...newTabs[index], [key]: value };
        setJsonSlice({ tabs: newTabs });
    };

    var addNewTab = () => {
        if (jsonSlice.tabs.length < 5) {
            setJsonSlice({ tabs: [...jsonSlice.tabs, { json: '' }] });
        }
    };

    var closeTab = (event, index) => {
        var { selectedTab, tabs } = jsonSlice;

        if (tabs.length > 1) {
            tabs = [...tabs];
            tabs.splice(index, 1);
            setJsonSlice({
                selectedTab: (selectedTab >= tabs.length) ? (tabs.length - 1) : selectedTab,
                tabs,
            });
        }

        event.stopPropagation();
    };

    var prettyPrint = () => {
        var newTabs = [...jsonSlice.tabs];
        var tab = newTabs[jsonSlice.selectedTab];
        try {
            var parsedJson = JSON.parse(tab.json);
            tab.json = JSON.stringify(parsedJson, null, 4);
            setJsonSlice({ tabs: newTabs });
        } catch (error) {
            // not valid json, so do nothing
        }
    };

    var minify = () => {
        var newTabs = [...jsonSlice.tabs];
        var tab = newTabs[jsonSlice.selectedTab];
        try {
            var parsedJson = JSON.parse(tab.json);
            tab.json = JSON.stringify(parsedJson);
            setJsonSlice({ tabs: newTabs });
        } catch (error) {
            // not valid json, so do nothing
        }
    };

    var { selectedTab, tabs } = jsonSlice;

    var json = tabs[selectedTab].json;

    return (
        <div className='jsonViewTool'>
            {/* <div className='jsonHeader'>JSON Viewer Tool</div> */}
            <div className='jsonViewContainer homeBoxShadow'>
                <div className='tabBar' style={{ gridTemplateColumns: `repeat(${tabs.length}, 1fr) 25px` }}>
                    {tabs.map((tab, index) => (
                        <div
                            className={classNames('tabButton', { selected: selectedTab === index })} key={index}
                            onClick={() => setJsonSlice({ selectedTab: index })}
                        >
                            Tab {index + 1}
                            <div className='closeButton' onClick={(event) => closeTab(event, index)}>X</div>
                        </div>
                    ))}
                    <div className={classNames('tabButton addButton')} onClick={addNewTab}>+</div>
                </div>
                {/* add prettify/minifiy buttons */}
                <div className='jsonTextContainer'>
                    <textarea className='jsonText' value={json} onChange={(event) => setTabState(selectedTab, 'json', event.target.value)} />
                    <div className='prettifyButtonsContainer'>
                        <Input type='button' label='Pretty Print' onClick={prettyPrint} />
                        <Input type='button' label='Minify' onClick={minify} />
                    </div>
                </div>
                <div className='jsonOutputContainer'>
                    <div className='jsonOutput'>
                        <JsonViewer json={json || null} />
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    );
}

export default JsonViewTool;
