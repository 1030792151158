import React, { useState } from 'react';

import './gridCalculator.scss';

function GridCalculator(props) {
    var [baseProperties, setBaseProperties] = useState({
        croppedWidth: 2051,
        gridWidth: 5,
        gridHeight: 3,
        overallWidth: 3840,
        overallHeight: 2160,
    });
    var { overallWidth, overallHeight, gridWidth, gridHeight, croppedWidth } = baseProperties;

    var newClipWidth =  Math.floor(overallWidth / gridWidth);
    var newClipHeight =  Math.floor(overallHeight / gridHeight);
    var newClipAspectRatio = newClipWidth / newClipHeight;

    var [calculatedProperties, setCalculatedProperties] = useState({
        newClipWidth,
        newClipHeight,
        newClipAspectRatio,
        expectedCropHeight: Math.floor(croppedWidth / newClipAspectRatio),
        scaleDownPercent: (newClipWidth / croppedWidth) * 100,
    });

    var handleNewNumber = (key, event) => {
        var value = event.target.value.replace(/[^0-9]/g, '');
        var nextState = { ...baseProperties, [key]: value };
        var newProps = calculateNewProperties(nextState);
        setCalculatedProperties({ ...calculatedProperties, ...newProps });
        setBaseProperties({ ...baseProperties, [key]: value });
    };

    var calculateNewProperties = (nextState) => {
        var newClipWidth =  Math.floor(nextState.overallWidth / nextState.gridWidth);
        var newClipHeight =  Math.floor(nextState.overallHeight / nextState.gridHeight);
        var newClipAspectRatio = newClipWidth / newClipHeight;
        var newProps = {
            newClipWidth,
            newClipHeight,
            newClipAspectRatio,
            expectedCropHeight: Math.floor(nextState.croppedWidth / newClipAspectRatio),
            scaleDownPercent: (newClipWidth / nextState.croppedWidth) * 100,
        };

        return newProps;
    };

    var { expectedCropHeight, scaleDownPercent } = calculatedProperties;

    return (
        <div id='gridCalculator'>
            <h1>Steps</h1>
            <ol>
                <li>
                    <div>Enter the dimensions (may be the different or the same)</div>
                    <div style={{ height: 'auto', paddingLeft: 30 }}>
                        <div>
                            Overall video:
                            <input type='text' onChange={(event) => handleNewNumber('overallWidth', event)} value={overallWidth} />
                            x
                            <input type='text' onChange={(event) => handleNewNumber('overallHeight', event)} value={overallHeight} />
                        </div>
                        <div>
                            Grid Size:
                            <input type='text' onChange={(event) => handleNewNumber('gridWidth', event)} value={gridWidth} />
                            x
                            <input type='text' onChange={(event) => handleNewNumber('gridHeight', event)} value={gridHeight} />
                        </div>
                    </div>
                </li>
                <li>
                    <div>New Values (Just for reference)</div>
                    <div style={{ height: 'auto', paddingLeft: 30 }}>
                        <div>New Clip Width: {newClipWidth}</div>
                        <div>New Clip Height: {newClipHeight}</div>
                        <div>New Clip Aspect Ratio: {newClipAspectRatio.toFixed(4)}</div>
                    </div>
                </li>
                <li>Crop the video how you want it</li>
                <li>
                    <div>Enter the width of your new crop (original width - left crop - right crop)</div>
                    <div style={{ height: 'auto', paddingLeft: 20 }}>
                        <input type='text' onChange={(event) => handleNewNumber('croppedWidth', event)} value={croppedWidth} />
                    </div>
                </li>
                <li>
                    <div>Crop the video to have the height: <span className='bold'>{expectedCropHeight}px</span></div>
                    <div>
                        So it would be (original height - top crop - bottom crop = {expectedCropHeight})
                    </div>
                    <div>
                        Or adjust the width to get a new height value. Crop the width more to get a smaller height, or make it wider to get a taller height.
                    </div>
                </li>
                <li>
                    <div>
                        Scale the video by this percent: <span className='bold'>{scaleDownPercent.toFixed(2)}%</span>
                    </div>
                </li>
            </ol>
        </div>
    );
}

export default GridCalculator;
