import React, { useState } from 'react';

import './ironCondorCalculator.scss';

function IronCondorCalculator(props) {
    var [threeUp, setThreeUp] = useState(0);
    var [twoUp, setTwoUp] = useState(0);
    var [oneUp, setOneUp] = useState(0);
    var [price, setPrice] = useState(0);
    var [oneDown, setOneDown] = useState(0);
    var [twoDown, setTwoDown] = useState(0);
    var [threeDown, setThreeDown] = useState(0);

    var handleNewPrice = (event) => {
        var value = event.target.value.replace(/[^0-9.]/g, '') || '';

        setThreeUp(Math.floor(value * 1.03));
        setTwoUp(Math.floor(value * 1.02));
        setOneUp(Math.floor(value * 1.01));
        setPrice(value);
        setOneDown(Math.ceil(value * 0.99));
        setTwoDown(Math.ceil(value * 0.98));
        setThreeDown(Math.ceil(value * 0.97));
    };

    return (
        <div id='ironCondorCalculator'>
            <h1>Iron Condor Calculator</h1>
            <div>Enter the current price:</div>
            <div style={{ height: 'auto', paddingLeft: 30 }}>
                <div>3% up: {threeUp}</div>
                <div>2% up: {twoUp}</div>
                <div>1% up: {oneUp}</div>
                <div>Price <input type='text' onChange={handleNewPrice} value={price} /></div>
                <div>1% down: {oneDown}</div>
                <div>2% down: {twoDown}</div>
                <div>3% down: {threeDown}</div>
            </div>
        </div>
    );
}

export default IronCondorCalculator;
